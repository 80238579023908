import React, { useState } from "react"
import { graphql } from "gatsby"
import Markdown from "react-markdown"
import Layout from "../components/common/layout"
import CommentiCard from "../components/cards/commentiCard"
import FadeInAnimation from "../components/animations/fade"
import commentiStyles from "../styles/pages/commenti.module.scss"
import { LinkRenderer, HeadingRenderer } from "../utilities/markdownUtils"
import markdownStyles from "../styles/common/markdown.module.scss"

const Commenti = ({ data, location }) => {
  const commenti = data.allStrapiCommentiLettureDomenicali.edges || []
  const [selected, setSelected] = useState(commenti[0])

  const handleSelectItem = (index) => setSelected(commenti[index])

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  const displayDate = new Date(selected.node.data).toLocaleDateString(
    "it-IT",
    options
  )

  return (
    <Layout
      pageMeta={{
        title: "Commenti alle Letture Domenicali",
        keywords: [
          "lettera apostolica",
          "commenti alle letture",
          "testimonianze di fede cristiana",
        ],
        description: "Commenti alle Letture Domenicali del Monastero di Saint-Oyen ",
        link: "/commenti-alle-letture-domenicali",
      }}
      location={location}
      cssClass={commentiStyles.pageWrapper}
    >
      <h2 className={commentiStyles.title}>
        Commenti alle letture domenicali del Monastero di Saint-Oyen
      </h2>
      <ul className={commentiStyles.commenti}>
        {commenti.length &&
          commenti.map((commento, i) => (
            <CommentiCard
              key={commento.node.data}
              commento={commento}
              handleSelectItem={handleSelectItem}
              i={i}
              selected={selected === commenti[i]}
            />
          ))}
      </ul>
      <article className={commentiStyles.selected}>
        <FadeInAnimation direction="right">
          <span className={commentiStyles.selectedDate}>{displayDate}</span>
          <h3 className={commentiStyles.selectedTitle}>
            {selected.node.titolo}
          </h3>
        </FadeInAnimation>
        <FadeInAnimation direction="up">
          <Markdown
            className={markdownStyles.testo}
            renderers={{ heading: HeadingRenderer, link: LinkRenderer }}
            source={selected.node.testo}
          />
        </FadeInAnimation>
      </article>
    </Layout>
  )
}

export default Commenti

export const query = graphql`
  query CommentiLettureQuery {
    allStrapiCommentiLettureDomenicali(sort: { order: DESC, fields: data }) {
      edges {
        node {
          autore {
            nome
          }
          titolo
          data
          testo
        }
      }
    }
  }
`
