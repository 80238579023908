import React from "react"
import { isDateEarlier, formatDate } from "../../utilities/dateUtils"
import commentiCardStyles from "../../styles/cards/commenti-card.module.scss"

const CommentiCard = ({ commento, handleSelectItem, i, selected }) => {
  const oneMonthAgo = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    new Date().getDate()
  ).toLocaleDateString("it-IT", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  const displayDate = new Date(commento.node.data).toLocaleDateString(
    "it-IT",
    options
  )

  const isCommentoRecente = isDateEarlier(
    oneMonthAgo,
    formatDate(commento.node.data)
  )

  const isCardValid = commento.node.titolo && commento.node.testo
  if (isCardValid) {
    return (
      <>
        {isCommentoRecente && (
          <li
            key={commento.node.strapiId}
            className={`${commentiCardStyles.card} ${
              selected && commentiCardStyles.active
            }`}
            onClick={() => handleSelectItem(i)}
            role="presentation"
          >
            <span>{displayDate}</span>
            <p>{commento.node.titolo}</p>
          </li>
        )}
      </>
    )
  }
  return null
}

export default CommentiCard
